<template>
    <div class="background-box">
        <div class="chat-container" :class="magnify && !phoneView ? 'chat-container-magnify' : phoneView && 'chat-container-phone'">
            <div class="chat-box">
                <div class="box-header">
                    <div class="header-title">小许同学</div>
                    <div style="text-align: right; position: relative">
                        <span style="position: absolute; right: 0; top: 5px">
                            <a-icon title="设置邀请码" type="safety" style="font-size: 24px; cursor: pointer; color: #888" @click="handleSetUserCode"/>
                        </span>
                        <span v-if="!phoneView" style="position: absolute; right: 35px; top: 3px">
                            <a-icon title="关闭全屏" v-if="magnify" type="fullscreen-exit" style="font-size: 20px; cursor: pointer; color: #888" @click="magnify = !magnify" @keydown.esc="magnify = !magnify"/>
                            <a-icon title="开启全屏" v-else type="fullscreen" style="font-size: 20px; cursor: pointer; color: #888" @click="magnify = !magnify"/>
                        </span>
                    </div>
                </div>
                <div ref="box_content" class="box-content">
                    <loading :loading="listLoading">
                        <div style="padding: 20px 0">
                            <div class="content-line" v-if="!talkerDataList || talkerDataList.length == 0">快开始我们的聊天吧~</div>
                            <div v-else>
                                <div class="content-line" style="font-size: 11px; color: #bbb">—<span style="padding: 0 10px">到此为止了</span>—</div>
                                <div v-for="(item, index) in talkerDataList" :key="index + '_' + item.timestamp">
                                    <div v-if="item.msgType === 'time'" class="content-line">{{item.content}}</div>
                                    <div v-if="item.msgType === 'talk_left'" class="content-msg content-left">
                                        <div class="msg-box msg-box-left">
                                            <div class="after-box"></div>
                                            <mark-down-view :content="item.content"/>
                                        </div>
                                    </div>
                                    <div v-if="item.msgType === 'talk_right'" class="content-msg content-right">
                                        <div class="msg-box msg-box-right">
                                            <p :key="index + '_' + item" v-for="(item, index) in splitContent(item.content)">{{item}}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="submitLoading" class="content-line">
                                <a-icon type="loading" />
                                <span style="margin-left: 10px">对方输入中…</span>
                            </div>
                        </div>
                    </loading>
                </div>
                <div class="box-footer">
                    <a-textarea class="footer-area" placeholder="输入消息" v-model="value" :autoSize="{minRows: 3, maxRows: 3}" :maxLength="1000" @keydown.enter.prevent="handleEnterKey"/>
                    <div class="footer-submit">
                        <span style="margin-right: 10px; color: #999">{{value.length}}/1000</span>
                        <span style="display: inline-block; width: 100px; text-align: right">
                            <a-button class="submit-btn" type="primary" block @click="handleSubmit" :disabled="submitLoading">发送</a-button>
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <get-user-code ref="get_user_code" @save="handleSaveUserCode"/>
    </div>
</template>

<script>
import GetUserCode from "./components/GetUserCode"
import MarkDownView from '@/components/MarkDownView'
import {listTalkerMsg, sendTalkerMsg} from '@/api/wxChat'

export default {
    name: "index",
    components: {
        GetUserCode,
        MarkDownView
    },
    data() {
        return {
            phoneView: false,
            magnify: false,
            listLoading: false,
            submitLoading: false,
            talkerDataList: [],
            total: 0,
            value: '',
            queryParams: {
                pageNum: 1,
                pageSize: 10,
                userCode: '',
                toUser: 'h5'
            }
        }
    },
    created() {
        this.listTalkerData()
    },
    mounted() {
        this.phoneView = this.$store.state.phoneView
    },
    watch: {
        '$store.state.phoneView'() {
            this.phoneView = this.$store.state.phoneView
        }
    },
    methods: {
        listTalkerData() {
            const userCode = localStorage.getItem("talker_user_code")
            if (!userCode || userCode.length == 0) {
                this.$refs['get_user_code'].init()
                return
            }
            this.listLoading = true
            this.queryParams.userCode = userCode
            listTalkerMsg(this.queryParams).then(res => {
                if (res.code === 200) {
                    this.talkerDataList = res.rows
                    this.total = res.total
                } else {
                    this.clearData()
                    this.$message.error(res.msg)
                }
            }).finally(()=> {
                this.listLoading = false
                this.scrollToBottom()
            })
        },
        clearData() {
            this.total = 0
            this.talkerDataList = []
        },
        scrollToBottom() {
            this.$nextTick(()=> {
                const cel = this.$refs['box_content']
                cel.scrollTop = cel.scrollHeight
            })
        },
        handleSetUserCode() {
            this.$refs['get_user_code'].init()
        },
        handleSubmit() {
            if (this.submitLoading) {
                return
            }
            const userCode = localStorage.getItem("talker_user_code")
            if (!userCode || userCode.length == 0) {
                this.$refs['get_user_code'].init()
                return
            }
            if (this.value.trim().length === 0) {
                this.value = ''
                return
            }
            if (this.value.length > 1000) {
                this.$message.warn("仅支持发送1000字以内的消息")
                return
            }
            this.addRightTalkerMsg()
            this.submitLoading = true
            sendTalkerMsg({userCode, toUser: this.queryParams.toUser, msg: this.value}).then(res => {
                if (res.code === 200) {
                    this.talkerDataList.push(res.data)
                } else {
                    this.clearData()
                    this.$message.error(res.msg)
                }
            }).finally(()=> {
                this.submitLoading = false
                this.scrollToBottom()
            })
            this.value = ''
            this.scrollToBottom()
        },
        addRightTalkerMsg() {
            const thisTime = new Date()
            const len = this.talkerDataList.length
            if (len > 0) {
                const lastTimestamp = this.talkerDataList[len - 1]['timestamp']
                if (thisTime.getTime() - lastTimestamp > 300000) {
                    this.talkerDataList.push({
                        msgType: 'time',
                        content: this.$formatDate(thisTime, 'YYYY-MM-DD HH:mm'),
                        timestamp: thisTime.getTime()
                    })
                }
            }
            this.talkerDataList.push({
                msgType: 'talk_right',
                content: this.value,
                timestamp: thisTime.getTime()
            })
        },
        handleEnterKey(event) {
            if (event.shiftKey || this.phoneView) {
                this.value += '\n'
            } else {
                this.handleSubmit()
            }
        },
        handleSaveUserCode() {
            this.listTalkerData()
        },
        splitContent(content) {
            return content.split('\n')
        }
    }
}
</script>

<style scoped lang="less">
.background-box {
    height: 100vh;
    width: 100%;
    background: #e9e9e9;
}
.chat-container {
    height: calc(100vh - 50px);
    max-width: 1000px;
    padding-top: 50px;
    margin: 0 auto;
    background: transparent;
    .chat-box {
        height: 100%;
        background: #fff;
        border-radius: 5px;
        box-shadow: 0 0 8px 0 rgba(0,0,0,.02);
        >div {
            width: 100%;
            background: #fff;
        }
        .box-header {
            box-sizing: border-box;
            height: 60px;
            line-height: 60px;
            border-bottom: 1px solid #e3e4e5;
            padding: 0 24px;
            border-radius: 5px 5px 0 0;
            >div {
                float: left;
                width: 50%;
                font-size: 15px;
            }
            .header-title {
                padding-left: 40px;
                position: relative;
            }
            .header-title:before {
                content: '';
                position: absolute;
                top: 15px;
                left: 0;
                height: 30px;
                width: 30px;
                background-image: url("~@/assets/image/talker_left.jpg");
                background-repeat: no-repeat;
                background-size: 30px;
                border-radius: 5px;
            }
        }
        .box-content {
            height: calc(100% - 220px);
            padding: 0 24px;
            box-sizing: border-box;
            overflow-y: auto;
            .content-line {
                line-height: 30px;
                text-align: center;
                color: #999;
                padding-bottom: 15px;
            }
            .content-msg {
                padding-bottom: 15px;
                width: 100%;
                overflow: hidden;
                .msg-box {
                    border: 1px solid #eee;
                    border-radius: 5px;
                    max-width: calc(90% - 50px);
                    padding: 10px 15px;
                }
                .msg-box-left {
                    float: left;
                    margin-left: 50px;
                    position: relative;
                    &:before {
                        content: '';
                        position: absolute;
                        top: 0px;
                        left: -50px;
                        height: 40px;
                        width: 40px;
                        background-image: url("~@/assets/image/talker_left.jpg");
                        background-repeat: no-repeat;
                        background-size: 40px;
                        border-radius: 5px;
                    }
                    &:after {
                        content: '';
                        position: absolute;
                        top: 10px;
                        left: -16px;
                        border-width: 10px;
                        border-style: solid;
                        border-color: transparent #eee transparent transparent;
                        z-index: 100;
                    }
                    .after-box {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 10px;
                        height: 100%;
                        background-color: #2196F3;
                        z-index: 101;
                        border-radius: 5px;
                        background-color: #fff;
                        &:after {
                            content: '';
                            position: absolute;
                            top: 10px;
                            left: -14px;
                            border-width: 10px;
                            border-style: solid;
                            border-color: transparent #fff transparent transparent;
                        }
                    }
                }
                .msg-box-right {
                    float: right;
                    margin-right: 50px;
                    background-color: #95ec69;
                    line-height: 20px;
                    font-size: 14px;
                    word-wrap:break-word;
                    position: relative;
                    &:before {
                        content: '';
                        position: absolute;
                        top: 0px;
                        right: -50px;
                        height: 40px;
                        width: 40px;
                        background-image: url("~@/assets/image/talker_right.jpg");
                        background-repeat: no-repeat;
                        background-size: 40px;
                        border-radius: 5px;
                    }
                    &:after {
                        content: '';
                        position: absolute;
                        top: 10px;
                        right: -16px;
                        border-width: 10px;
                        border-style: solid;
                        border-color: transparent transparent transparent #95ec69;
                    }
                    /deep/ * {
                        background: transparent!important;
                    }
                    p {
                        margin-bottom: 5px;
                    }
                    p:last-child {
                        margin: 0;
                    }
                }
            }
        }
        .box-footer {
            box-sizing: border-box;
            height: 160px;
            padding: 24px;
            border-top: 1px solid #e3e4e5;
            border-radius: 0 0 5px 5px;
            position: relative;
            .footer-area {
                padding: 0;
                border: 0;
                resize:none;
                border-radius: 0;
                &:hover {
                    border: 0;
                }
                &:focus {
                    border: 0!important;
                    box-shadow: 0 0 0 0 transparent!important;
                }
            }
            .footer-submit {
                position: absolute;
                bottom: 24px;
                right: 24px;
                .submit-btn {
                    background-color: #07c160;
                    border-color: #07c160;
                }
            }
        }
    }
}
.chat-container-magnify {
    height: 100vh;
    padding: 10px;
    max-width: 100%;
}
.chat-container-phone {
    padding: 0;
    height: 100vh;
}
/deep/ .v-note-wrapper {
    min-height: auto;
    min-width: auto;
    p {
        margin-top: 0;
        margin-bottom: 5px;
        line-height: 20px;
        font-size: 14px;
    }
    p:last-child {
        margin-bottom: 0
    }
    * {
        line-height: 25px;
        font-size: 14px;
        text-align: justify;
    }
}
</style>
