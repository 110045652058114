<template>
    <div class="markdown-view-bg">
        <mavon-editor ref="mavonEditorId"
                      :subfield="false"
                      :editable="false"
                      :toolbarsFlag="false"
                      :navigation="false"
                      :shortCut="false"
                      :boxShadow="false"
                      defaultOpen="preview"
                      previewBackground="#fff"
                      :xssOptions="false"
                      :codeStyle="codeStyle"
                      v-model="realContent"/>
    </div>
</template>

<script>

// 组件地址 https://github.com/hinesboy/mavonEditor
import 'mavon-editor/dist/css/index.css';
import { mavonEditor } from 'mavon-editor'

export default {
    name: "index",
    components: {mavonEditor},
    props: {
        content: {
            type: String,
            default() {return ''}
        },
        codeStyle: {
            type: String,
            default() {return 'github'}
        },
    },
    data() {
        return {
            realContent: ''
        }
    },
    created() {
        this.realContent = this.content
    },
    mounted() {
        this.$nextTick(() => {
            this.$emit('init', this.getCatalogList())
        })
    },
    methods: {
        getCatalogList() {
            if (!this.content) {
                return []
            }
            let minIndex = 6
            let catalogs = []
            let nodes = this.$refs.mavonEditorId.$refs.vShowContent.childNodes
            if (nodes.length > 0) {
                for (const node of nodes) {
                    const reg = /^H[1-6]{1}$/
                    if (!reg.exec(node.tagName) || !node.hasChildNodes()) {
                        continue
                    }
                    for (const _node of node.childNodes) {
                        if (_node.tagName === 'A') {
                            const index = Number.parseInt(node.tagName.replace('H', ''))
                            if (index < minIndex) {
                                minIndex = index
                            }
                            catalogs.push({
                                id: _node.id,
                                name: node.innerText,
                                tagName: node.tagName,
                                index: index
                            })
                        }
                    }
                }
            }
            if (catalogs.length > 0 && minIndex > 1) {
                // eslint-disable-next-line no-unused-vars
                catalogs.map(item => {
                    item.index -= (minIndex - 1)
                })
            }
            return catalogs
        }
    }

}
</script>

<style scoped lang="less">
/deep/ .hljs {
    background: transparent;
}
/deep/ .v-note-wrapper {
    border: none;
}
/deep/ .v-note-wrapper .v-note-panel .v-note-show .v-show-content, .v-note-wrapper .v-note-panel .v-note-show .v-show-content-html {
    padding: 0;
}
</style>
