import request from "@/util/request";


/**
 * 获取邀请用户聊天记录
 * @returns {*}
 */
export function listTalkerMsg(params) {
    return request({
        url: '/chat_wx/list_talker_msg',
        method: 'get',
        params: params,
        timeout: 30000
    })
}

/**
 * 发送聊天消息
 * @returns {*}
 */
export function sendTalkerMsg(data) {
    return request({
        url: '/chat_wx/send_talker_msg',
        method: 'post',
        data: data,
        timeout: 60000
    })
}
